.login-btn {
  background-color: #906fc8 !important;
  /* border-radius: 50px !important; */
  padding: 4px 34px 4px 34px !important;
  font-size: 15px !important;
}

.link-register {
  padding: 0px 2rem 0 1rem !important;
  font-size: 18px !important;
  color: #906fc8 !important;
}
.header-date-time {
  padding: 0px 1rem 0 2rem !important;
  font-size: 15px !important;
  font-size: 18px !important;
  color: #929292 !important;
}

.vertical-line {
  border-left: rgba(7, 9, 25, 0.1) solid 2px;
  height: 45px;
  /* position:absolute;  */
}

.header-dashboard {
  padding: 0px 1rem 0 1rem !important;
  font-size: 15px !important;
  font-size: 18px !important;
  color: #929292 !important;
}

.header-FOYR {
  padding: 0px 1rem 0 1rem !important;
  font-size: 15px !important;
  font-size: 18px !important;
  color: #929292 !important;
}

.header-site-visit {
  padding: 0px 1rem 0 1rem !important;
  font-size: 15px !important;
  font-size: 18px !important;
  color: #929292 !important;
  cursor: pointer;
}

.header-brochure {
  padding: 0px 2rem 0 1rem !important;
  font-size: 15px !important;
  font-size: 18px !important;
  color: #929292 !important;
}

.add-site-visit {
  display: none !important;
}

/* site vist start */

.btn-full-with {
  width: 100%;
}
.step-btn-padding {
  padding: 0px 50px 10px 50px;
}

.date-picker-add-meeting {
  width: 100%;
}

.min-with-modal {
  /* min-width: 435px; */
  min-height: 665px;
  /* min-width: 435px; */
  max-width: 665px;
  /* flex-basis: auto; */
  flex-grow: 1;
}

.save-icon {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.user-booking-draft {
  margin: 0 !important;
  text-align: center;
}

.meeting-date-time-show {
  text-align: center;
  display: block;
}

.btn-edit-white {
  color: white;
}
.btn-edit-black {
  color: #929292;
}
.btn-share-black {
  color: #929292;
}

.btn-share-white {
  color: white !important;
}

.text-contact-number {
  margin-left: 270px;
}

.btn-add-new-meeting {
  background-color: #405361 !important;
  color: white !important;
}
.btn-default-black {
  background-color: #405361 !important;
  color: white !important;
}
.btn-add-new-meeting :hover {
  background-color: #405361 !important;
  color: white !important;
}

.btn-wrapper--label {
  color: white;
}

/* .MuiStep-vertical{
      margin-left: auto;
      margin-right: auto;
  } */
.d-50 {
  height: 25px !important;
  line-height: 50px !important;
  width: 25px !important;
}

.MuiTypography-displayBlock {
  text-align: start !important;
  color: #fff;
}
.MuiStepLabel-label.MuiStepLabel-active {
  color: #fff;
}
.MuiStepLabel-label.MuiStepLabel-completed {
  color: #fff;
}
.MuiStepper-root {
  background-color: transparent;
  padding-left: 60px;
}

.MuiStepConnector-vertical {
  padding: 1px 0px 0px 1px;
  margin-left: 10px;
}

.bg-composed-wrapper--content {
  padding: 0px 50px 180px 50px !important;
}

/* .MuiStepConnector-lineVertical {
      min-height: 24px;
      border-left-style: solid;
      border-left-width: 6px;
  }
  
  .MuiStepConnector-line {
      display: block;
      border-color: #fff;
  }
  
  .bg-gray-400 {
      background: #ffffff !important;
  } */

.display-3 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
}
.MuiButton-root:hover {
  text-decoration: none;
  background-color: rgb(64 83 97) !important;
}

.MuiDialog-paperScrollBody {
  box-shadow: none;
}

.icon-round {
  /* display: inline-block; */
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  /* padding: 0.5em 0.6em */
  border: 3px solid #405361;
  height: 75px;
  width: 75px;
  margin-bottom: 30px;
}
.MuiStepConnector-vertical {
  padding: 0px 0px 0px 0px;
  margin-left: 9px;
}

.MuiStepConnector-lineVertical {
  min-height: 50px;
  border-left-style: solid;
  border-left-width: 7px;
}
.MuiStepConnector-line {
  display: block;
  border-color: #e6e7f1;
}

.icon-default-black {
  color: #405361;
}
/* site vist end */

.d-50 {
  height: 25px !important;
  line-height: 50px !important;
  width: 25px !important;
}

.barnd-name {
  font-size: 26px !important;
  color: #906fc8 !important;
}
.on-hover:hover {
  text-decoration: none;
  background-color: #906fc8 !important;
}
.brand-logo-2{
  width: auto!important;
  height: auto!important;
}
.form-menu-icon {
  width: 50px!important;
  height: 50px!important;
  border: 3px solid #a6a6b9!important;
  color: #a6a6b9!important;
  display: flex!important;
  align-items: center!important;
  font-weight: 700!important;
  justify-content: center!important;
  flex-shrink: 0!important;
  border-radius: 100%!important;
  transition: all .2s ease-in-out!important;
  position: relative!important;
  }
.form-menu-icon-active{
  width: 50px!important;
  height: 50px!important;
  border: 3px solid #906fc8!important;
  background-color: transparent!important;
  color: #906fc8!important;
  display: flex!important;
  align-items: center!important;
  font-weight: 700!important;
  justify-content: center!important;
  flex-shrink: 0!important;
  border-radius: 100%!important;
  transition: all .2s ease-in-out!important;
  position: relative!important;
}
  .color-red{
    color: red!important;
  }